import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { trimToNumber } from 'src/app/services/Helper';
import { EntitySelectorTableColumn } from '../entity_selector_table/entitySelectorTable';
import Decimal from 'decimal.js';

@Component({
	selector: 'product-upcharge-toggle',
	template: `
		@if(this.data && this.data.value) {
		<div class="flex-container flex-container-row flex-gap">
			@for(value of validUpchargeValues; track value) {
			<button mat-mini-fab [color]="this.selectedValue == value ? 'primary' : 'secondary'" (click)="upchargeButtonClicked($event, value)">
				{{ value * 100 }}%
			</button>
			}
		</div>
		}
	`,
	styleUrls: ['./product_upcharge.scss'],
})
export class ProductUpchargeToggle implements OnInit {
	public data: UntypedFormGroup;

	public originalCost: number;
	public selectedValue = null;
	public upchargeControl = null;

	public validUpchargeValues: number[] = [0.1, 0.2, 0.3];

	constructor() {}

	public ngOnInit(): void {
		this.upchargeControl = this.data.controls.upcharge;
		this.selectedValue = this.upchargeControl.value;

		//		Set the original item cost before any modifications are applied
		let currentCost = new Decimal(trimToNumber(this.data.value.cost, 2));
		let upcharge = new Decimal(trimToNumber(this.upchargeControl.value ? this.upchargeControl.value : 0, 2));

		this.originalCost = trimToNumber(currentCost.div(1 + upcharge.toNumber()).toNumber(), 2);
	}

	public upchargeButtonClicked(event: MatButtonToggleChange, value: number) {
		if (this.upchargeControl.value == value) {
			this.upchargeControl.patchValue(null);
		} else {
			this.upchargeControl.patchValue(value);
		}

		this.selectedValue = this.upchargeControl.value;
		this.data.value.cost = trimToNumber(this.originalCost + this.originalCost * (this.upchargeControl.value ? this.upchargeControl.value : 0), 2);

		this.data.get('cost').patchValue(this.data.value.cost);
	}
}
