//		Native angular imports
import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//		Source code / custom components / custom modules imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/appComponent';
import { LoginComponent } from './components/login/loginComponent';
import { HomeComponent } from './components/home/homeComponent';
import { ApiService, LoadingServiceInterceptor } from './services/api.service';
import { UserService } from './services/user.service';
import { UserComponent } from './components/users/user/userComponent';
import { UsersComponent } from './components/users/users';
import { PageNotFoundComponent } from './components/page_not_found/pageNotFoundComponent';
import { InvoicesComponent } from './components/invoices/invoicesComponent';
import { RepairOrdersComponent } from './components/repair_orders/repairOrdersComponent';
import { CustomersComponent } from './components/customers/customersComponent';
import { NewPurchaseOrderComponent } from './components/purchase_orders/purchase_order/purchaseOrder.component';
import { ConfirmModal } from './components/confirm_modal/confirmModal';
import { RepairOrderComponent } from './components/repair_orders/repair_order/repairOrder.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CustomerComponent } from './components/customers/customer/customer.component';
import { VehiclesComponent } from './components/vehicles/vehiclesComponent';
import { VehicleComponent } from './components/vehicles/vehicle/vehicle.component';
import { ReceivingComponent } from './components/purchase_orders/receiving/receiving.component';
import { ReceivingPurchaseOrderComponent } from './components/purchase_orders/receiving_purchase_order/receivingPurchaseOrder.component';
import { GetVehicleProductsModal } from './components/vehicles/get_vehicle_products_modal/getVehicleProductsModal';
import { UserClockInComponent } from './components/user_clock_in/userClockIn.component';
import { PayrollComponent } from './components/payroll/payroll.component';
import { UserFriendlyHours } from './pipes/hours.pipe';
import { StoresComponent } from './components/stores/stores.component';
import { StoreComponent } from './components/stores/store/store.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LoadingButton } from './components/loading_button/loadingButton';
import { VehicleInventory } from './components/vehicles/vehicle_inventory/vehicleInventory.component';
import { GoogleModule } from './modules/google/google.module';
import { MaterialModule } from './modules/material/material.module';
import { StorePicker } from './components/stores/store_picker/storePicker.component';
import { EntitySelectorTable, EntitySelectorCustomComponent } from './components/entity_selector_table/entitySelectorTable';
import { VehiclePicker } from './components/vehicles/vehicle_picker/vehiclePicker.component';
import { UserPicker } from './components/users/user_picker/userPicker.component';
import { SnackBarAlert } from './components/snack_bar_alert/snackBarAlert';
import { CustomerPicker } from './components/customers/customer_picker/customerPicker';
import { PreventativeMaintenancePage } from './components/preventative_maintenance/preventative_maintenance_page/page';
import { PreventativeMaintenanceRecordComponent } from './components/preventative_maintenance/record_component/record.component';
import { PreventativeMaintenanceConfigurationComponent } from './components/preventative_maintenance/configuration_component/configuration.component';
import { PreventativeMaintenanceSelector } from './components/preventative_maintenance/preventative_maintenance_page/selector';
import { PhotoInput } from './components/file_input/photo_input.component';
import { ClickToDownload } from './directives/clickToDownload.directive';
import { ThemeSwitcher } from './components/theme_switcher/theme_switcher';
import { Quotes } from './components/qoutes/quotes';
import { QuoteComponent, UserSelectModal } from './components/qoutes/quote/quote';
import { CustomerLaborRateComponent } from './components/customers/customer/customer_labor_rate.component';
import { DateTimePicker } from './components/datetime_picker/datetime_picker.component';
import { LoadingSpinnerComponent } from './components/loading_spinner/loading_spinner';
import { ProductUpchargeToggle } from './components/product_upcharge/product_upcharge.component';
import { AppSettingsComponent } from './components/settings/app_settings/app_settings.component';
import { NoScrollInputDirective } from './directives/noScroll';

export let InjectorInstance: Injector;

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HomeComponent,
		UsersComponent,
		UserComponent,
		PageNotFoundComponent,
		RepairOrdersComponent,
		InvoicesComponent,
		CustomersComponent,
		NewPurchaseOrderComponent,
		ConfirmModal,
		RepairOrderComponent,
		SettingsComponent,
		CustomerComponent,
		VehiclesComponent,
		VehicleComponent,
		ReceivingComponent,
		ReceivingPurchaseOrderComponent,
		GetVehicleProductsModal,
		UserClockInComponent,
		PayrollComponent,
		UserFriendlyHours,
		StoresComponent,
		StoreComponent,
		ReportsComponent,
		LoadingButton,
		VehicleInventory,
		StorePicker,
		EntitySelectorTable,
		VehiclePicker,
		UserPicker,
		SnackBarAlert,
		CustomerPicker,
		PreventativeMaintenancePage,
		PreventativeMaintenanceConfigurationComponent,
		PreventativeMaintenanceRecordComponent,
		PreventativeMaintenanceSelector,
		ProductUpchargeToggle,
		EntitySelectorCustomComponent,
		PhotoInput,
		ClickToDownload,
		ThemeSwitcher,
		Quotes,
		QuoteComponent,
		UserSelectModal,
		CustomerLaborRateComponent,
		DateTimePicker,
		LoadingSpinnerComponent,
		AppSettingsComponent,
		NoScrollInputDirective,
	],
	bootstrap: [AppComponent],
	imports: [BrowserModule, AppRoutingModule, CommonModule, ReactiveFormsModule, FormsModule, BrowserAnimationsModule, GoogleModule, MaterialModule],
	providers: [
		ApiService,
		UserService,
		CurrencyPipe,
		DatePipe,
		UserFriendlyHours,
		provideHttpClient(withInterceptorsFromDi()),
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingServiceInterceptor, multi: true },
	],
})
export class AppModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
