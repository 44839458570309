import { Injectable } from '@angular/core';

import { ConfirmModal } from '../../components/confirm_modal/confirmModal';
import { MatDialog } from '@angular/material/dialog';
import { RepairOrderComponent } from 'src/app/components/repair_orders/repair_order/repairOrder.component';
import { LocalStorageService } from 'src/app/services/local_storage.service';
import { repairOrderStorageKey } from 'src/app/components/repair_orders/repairOrdersComponent';

@Injectable()
export class RepairOrderCanDeactivateGuard {
	constructor(private dialog: MatDialog, private storage: LocalStorageService) {}

	public async canDeactivate(repairOrderComponent: RepairOrderComponent): Promise<boolean> {
		if (repairOrderComponent.orderForm?.dirty) {
			const dialogRef = this.dialog.open(ConfirmModal, { width: '250px' });
			dialogRef.componentInstance.dialogue = 'It looks like you have not saved your order. Would you like to save? ';

			if (repairOrderComponent.orderForm.value.id) {
				dialogRef.componentInstance.dialogue += 'If not, your changes will be temporarily cached and can be loaded later.';
			}

			const dialogResponse = await dialogRef
				.afterClosed()
				.toPromise()
				.then(async (result) => {
					if (result) {
						await repairOrderComponent.onRepairOrderSubmit();
					} else {
						//		Cache the unsaved changes for later
						this.storage.add(repairOrderStorageKey, repairOrderComponent.orderForm.value.id, repairOrderComponent.orderForm.getRawValue());
					}
				});
		} else {
			return Promise.resolve(true);
		}
	}
}
