import { Component, ViewChild, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { RepairOrder, RepairOrderProcessingStep } from '../../classes/repairOrder';
import { RepairOrderComponent } from './repair_order/repairOrder.component';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { LocalStorageService } from '../../services/local_storage.service';
import { MatTableDataSource } from '@angular/material/table';
import { EntitySelectorTable, EntitySelectorTableColumn, EntitySelectorTableColumnType } from '../entity_selector_table/entitySelectorTable';
import { SnackBarService } from '../snack_bar_alert/snackBarAlert';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device.service';
import { FormControl } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';

enum StatusFilter {
	All = 1,
	Open = 2,
	Closed = 3,
	Complete = 4,
}

enum Mode {
	Merge = 'merge',
	Normal = 'normal',
}

export const repairOrderStorageKey = 'repairOrder';

@Component({
	templateUrl: './repairOrders.html',
	styleUrls: ['./repairOrders.scss'],
	selector: 'repair-orders',
})
export class RepairOrdersComponent implements OnInit {
	public filterText = '';
	public offset = 0;
	public mode = Mode.Normal;

	public tableDataSource = new MatTableDataSource<RepairOrder>();
	public entityTableFilter = new FormControl();

	public initializing: boolean = false;
	public selectorTableColumns: EntitySelectorTableColumn[] = [];

	@ViewChild(RepairOrderComponent) repairOrder: RepairOrderComponent;
	@ViewChild(EntitySelectorTable) table: EntitySelectorTable;

	public statusFilter: StatusFilter = StatusFilter.All;

	constructor(
		public userService: UserService,
		private api: ApiService,
		private storage: LocalStorageService,
		public dialog: MatDialog,
		public datePipe: DatePipe,
		public activatedRoute: ActivatedRoute,
		public device: DeviceService,
		public loadingService: LoadingService,
		private router: Router
	) {}

	/**
	 * Initializes the component by setting up the UI state to initializing and configuring columns for the selector table.
	 * Handles the display of various columns such as Id, Customer, Serviced By, Vehicle Unit#, Status, Close Date, Complete Date, and Billed.
	 * Manages the filtering of repair orders based on the status filter and triggers the searching of repair orders.
	 * Loads orders based on the provided ID, updates the view, and handles mode toggling between Normal and Merge.
	 * Handles the action when the back button is clicked, prompting the user to save unsaved changes.
	 * Refreshes the list of repair orders, resets the offset, and clears existing orders array.
	 * Handles the search functionality for repair orders and loading of orders with user permissions considered.
	 */
	public ngOnInit() {
		// Set UI state to initializing
		this.initializing = true;

		//		Set up the columns for the selector table
		let editButton = new EntitySelectorTableColumn();
		editButton.columnHeader = '';
		editButton.columnProperty = 'edit';
		editButton.columnWidth = '60px';
		editButton.type = EntitySelectorTableColumnType.button;
		editButton.typeOptions = {
			icon: 'edit',
			click: (order: RepairOrder) => {
				this.router.navigate([order.id], { relativeTo: this.activatedRoute });
			},
		};
		this.selectorTableColumns.push(editButton);

		let idColumn = new EntitySelectorTableColumn();
		idColumn.columnHeader = 'Id';
		idColumn.columnWidth = '65px';
		idColumn.columnProperty = 'id';
		idColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(idColumn);

		let nameColumn = new EntitySelectorTableColumn();
		nameColumn.columnHeader = 'Customer';
		nameColumn.columnProperty = 'customer.name';
		nameColumn.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(nameColumn);

		let servicedBy = new EntitySelectorTableColumn();
		servicedBy.columnHeader = 'Serviced By';
		servicedBy.columnProperty = 'user';
		servicedBy.type = EntitySelectorTableColumnType.data;
		servicedBy.getData = (order: RepairOrder) => {
			if (!order || !order.user) {
				return 'Not set';
			}

			return `Emp# ${order.user.employee_number || ''}`;
		};

		this.selectorTableColumns.push(servicedBy);

		let unitNumber = new EntitySelectorTableColumn();
		unitNumber.columnHeader = 'Vehicle Unit#';
		unitNumber.columnProperty = 'vehicle.unit_number';
		unitNumber.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(unitNumber);

		let status = new EntitySelectorTableColumn();
		status.columnHeader = 'Status';
		status.columnProperty = 'repairOrderProcessingStepId';
		status.getData = (order: RepairOrder) => {
			let state = '';

			switch (order.repairOrderProcessingStepId) {
				case RepairOrderProcessingStep.New:
					state = ' Open ';
					break;
				case RepairOrderProcessingStep.Closed:
					state = ' Closed ';
					break;
				case RepairOrderProcessingStep.NeedsBilled:
					state = ' Open ';
					break;
				case RepairOrderProcessingStep.Complete:
					state = ' Complete ';
					break;
				default:
					break;
			}

			if (!order.lineItems || !order.lineItems.length) {
				state += 'Unsubmitted';
			}

			return state;
		};
		status.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(status);

		let closeDate = new EntitySelectorTableColumn();
		closeDate.columnHeader = 'Close Date';
		closeDate.columnProperty = 'close_date';
		closeDate.getData = (element) => {
			let date = '';

			if (element.close_date) {
				date = this.datePipe.transform(new Date(element.close_date), 'shortDate');
			}

			return date;
		};
		closeDate.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(closeDate);

		let completeDate = new EntitySelectorTableColumn();
		completeDate.columnHeader = 'Complete Date';
		completeDate.columnProperty = 'complete_date';
		completeDate.getData = (element) => {
			let date = '';

			if (element.complete_date) {
				date = this.datePipe.transform(new Date(element.complete_date), 'short');
			}

			return date;
		};
		completeDate.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(completeDate);

		let isBilled = new EntitySelectorTableColumn();
		isBilled.columnHeader = 'Billed';
		isBilled.columnProperty = 'isBilled';
		isBilled.getData = (element: RepairOrder) => {
			if (element.isBilled) {
				return 'Yes';
			}

			return 'No';
		};
		isBilled.type = EntitySelectorTableColumnType.data;

		this.selectorTableColumns.push(isBilled);

		this.tableDataSource.filterPredicate = (order: RepairOrder, filterText: string) => {
			let name = order.customer.name + order.vehicle.unit_number;

			//		name == ' ' when we have to manually trigger filtering when the status filter is updated
			if (name == null || name == undefined || name == ' ') name = '';

			let includedByStatus = null;

			switch (this.statusFilter) {
				case StatusFilter.Open:
					includedByStatus = order.repairOrderProcessingStepId != RepairOrderProcessingStep.Closed && !order.close_date;
					break;
				case StatusFilter.Closed:
					includedByStatus = order.close_date != null;
					break;
				case StatusFilter.Complete:
					includedByStatus = order.complete_date != null;
					break;
				case StatusFilter.All:
					includedByStatus = true;
				default:
					break;
			}

			return name.trim().toLowerCase().indexOf(filterText.trim().toLowerCase()) > -1 && includedByStatus;
		};

		//      Initialize searching of repair orders
		this.searchRepairOrders();

		this.loadOrders().then(() => {
			if (this.activatedRoute.snapshot.queryParams.id) {
				let order = this.tableDataSource.data.find((order: RepairOrder) => order.id == this.activatedRoute.snapshot.queryParams.id);

				if (order) {
					this.router.navigate([order.id], { relativeTo: this.activatedRoute });
				}
			}

			this.initializing = false;
		});
	}

	/* This function toggles the mode between Normal and Merge. In Normal mode, the user can edit and view individual repair orders.
	 * In Merge mode, the user can select multiple repair orders and merge them into one. The function also adds a checkbox and button column to the entity selector table when in
	 * Merge mode. The checkbox allows the user to select multiple repair orders and the button allows the user to merge them into one. The function also adds a checkbox data property
	 * to the repair orders and sets it to false. When the mode is changed back to Normal, the checkbox and button columns are removed and the checkbox data property is deleted.
	 * The function also updates the displayed columns in the table. The function returns a promise.
	 */
	public toggleMode = () => {
		this.mode = this.mode == Mode.Normal ? Mode.Merge : Mode.Normal;

		if (this.mode == Mode.Merge) {
			//		Add a check box data property to the repair orders
			this.tableDataSource.data.forEach((order: any) => {
				order.selected = false;
			});

			//		Add a button and check box column to the entity selector table
			let mergeButton = new EntitySelectorTableColumn();
			mergeButton.columnHeader = '';
			mergeButton.columnProperty = 'merge';
			mergeButton.columnWidth = '60px';
			mergeButton.type = EntitySelectorTableColumnType.asyncButton;
			mergeButton.typeOptions = {
				icon: 'link',
				click: (order: RepairOrder) => {
					//	Gather all selected repair orders
					let selectedOrderIds: number[] = (this.tableDataSource.data.filter((order: any) => order.selected) as RepairOrder[]).map(
						(order) => order.id
					);
					let thisOrderIndex = selectedOrderIds.findIndex((id) => id == order.id);

					if (thisOrderIndex > -1) {
						selectedOrderIds.splice(thisOrderIndex, 1);
					}

					if (selectedOrderIds.length > 0) {
						return this.api
							.mergeIntoRepairOrder(order.id, selectedOrderIds)
							.toPromise()
							.then(() => {
								SnackBarService.openSnackBarAlert('Merge successful. The repair orders table has now been refreshed.');
								return this.onRefresh({ id: null });
							});
					}

					SnackBarService.openSnackBarAlert('Please select repair orders to merge into this order.', 'red');
					return Promise.resolve();
				},
				materialType: 'mat-mini-fab',
			};
			this.selectorTableColumns.unshift(mergeButton);

			let checkbox = new EntitySelectorTableColumn();
			checkbox.columnHeader = '';
			checkbox.columnProperty = 'selected';
			checkbox.columnWidth = '35px';
			checkbox.type = EntitySelectorTableColumnType.checkbox;

			this.selectorTableColumns.unshift(checkbox);

			this.table.updateDisplayedColumns();
		} else {
			this.selectorTableColumns.shift();
			this.selectorTableColumns.shift();
			this.table.updateDisplayedColumns();

			//		Add a check box data property to the repair orders
			this.tableDataSource.data.forEach((order: any) => {
				delete order.selected;
			});
		}

		return Promise.resolve();
	};

	public statusFilterUpdated(event) {
		this.statusFilter = event.value;

		//		Force filtering to reoccur
		this.tableDataSource.filter = this.tableDataSource.filter + ' ';
	}

	public loadMoreOrders = () => {
		this.offset += 200;

		return this.loadOrders();
	};

	/**
	 * Refreshes the list of repair orders based on the provided ID.
	 * Resets the offset to 0 to load the first 50 orders and clears the existing orders array.
	 * If the ID is provided, finds the order with the matching ID and loads it.
	 * Restores the previous selected line item index and tab index if available.
	 * Subscribes to the repair order initialization and updates the view accordingly.
	 * If no ID is provided, resets the selected order to null.
	 * Returns a promise after the refresh operation is completed.
	 */
	public onRefresh(args: { id: number }) {
		//      Reset the offset to 0 to load the first 50
		//      Clear out the orders array
		this.offset = 0;
		this.tableDataSource.data = [];

		let lineItemIndex = null;
		let tabIndex = null;
		if (this.repairOrder) {
			lineItemIndex = this.repairOrder.selectedLineItemIndex;
			tabIndex = this.repairOrder.selectedTabIndex;
		}

		return this.loadOrders();
	}

	public repairOrdersSearchLoading = false;
	public repairOrderSearchInput = new FormControl('');
	public searchedRepairOrders: Observable<Array<any>> = null;
	/**
	 * Searches for repair orders based on the input value.
	 * Utilizes debounce time of 200ms, distinct values, and loading indicators.
	 * Handles errors and updates loading status accordingly.
	 */
	private searchRepairOrders() {
		this.searchedRepairOrders = this.repairOrderSearchInput.valueChanges.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			tap(() => (this.repairOrdersSearchLoading = true)),
			switchMap((term) =>
				this.api.searchRepairOrders(term).pipe(
					switchMap((response) => {
						return of(response);
					}),
					catchError(() => of([])),
					tap(() => (this.repairOrdersSearchLoading = false))
				)
			)
		);
	}

	/**
	 * Handles the change event when a repair order is selected for search.
	 * If a valid item is selected, loads the order details based on the selection.
	 * If the order is found in the existing data, loads the order in Normal mode.
	 * If the order is not found and the mode is not Merge, loads the selected order.
	 * If the mode is Merge, adds the selected order to the top of the list.
	 * Updates the loading status accordingly.
	 *
	 * @param selectedItem The selected item representing a repair order.
	 */
	public onSearchRepairOrdersChange(event: any) {
		const selectedOrder = event?.option?.value;

		if (selectedOrder && selectedOrder.id) {
			this.repairOrdersSearchLoading = true;

			let downloadedOrder = this.tableDataSource.data.find((order: RepairOrder) => order.id == selectedOrder.id);
			if (downloadedOrder) {
				if (this.mode == Mode.Normal) {
					this.router.navigate([downloadedOrder.id], { relativeTo: this.activatedRoute });
				}

				this.repairOrdersSearchLoading = false;
			} else {
				if (this.mode != Mode.Merge) {
					this.router.navigate([selectedOrder.id], { relativeTo: this.activatedRoute });
				} else {
					let data = this.tableDataSource.data;
					data.unshift(selectedOrder);
					this.tableDataSource.data = data;
				}

				this.repairOrdersSearchLoading = false;
			}
		}
	}

	/**
	 * Loads repair orders from the API based on the offset.
	 * Filters the orders based on user permissions.
	 * Sorts the orders by ID in descending order.
	 * Appends the loaded orders to the existing data source.
	 * Returns a promise after the loading process is completed.
	 * @returns A promise after loading the orders.
	 */
	private loadOrders(): Promise<any> {
		return this.api
			.getAllRepairOrders(this.offset, false)
			.toPromise()
			.then((results) => {
				let orders: RepairOrder[] = results;

				//		Filter down the repair orders to only the ones that the user is able to see
				if (!this.userService.isAdmin()) {
					if (this.userService.isManager()) {
						orders = orders.filter(
							(repairOrder) => (repairOrder.close_date && !repairOrder.complete_date) || repairOrder.user.id == this.userService.user.id
						);
					} else {
						orders = orders.filter((repairOrder) => repairOrder.user.id == this.userService.user.id);
					}
				}

				if (orders && orders.length) {
					orders = orders.sort((a, b) => {
						let aOrder = (a.id && a.id.toString().toLowerCase()) || '';
						let bOrder = (b.id && b.id.toString().toLowerCase()) || '';

						if (aOrder > bOrder) return -1;
						if (aOrder < bOrder) return 1;
						return 0;
					});
				}

				this.tableDataSource.data = this.tableDataSource.data.concat(orders);
			})
			.catch((error) => {
				console.error(error);
				SnackBarService.openSnackBarAlert(`Error occurred while loading repair orders. Error: ${error}`, 'red');
			});
	}

	public newOrderClicked = () => {
		this.router.navigate(['new'], { relativeTo: this.activatedRoute });
	};
}
