export class Product {
	public id: number;
	public part_number: string;
	public description: string;
	public quantity: number;
	public cost: number;
	public upcharge: number;

	constructor(data?: Product) {
		if (data) {
			this.id = data.id;
			this.part_number = data.part_number;
			this.description = data.description;
			this.quantity = data.quantity;
			this.cost = data.cost;
			this.upcharge = data.upcharge;
		}
	}
}
