import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, model, output, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DeviceService } from 'src/app/services/device.service';
import { ConfirmModal } from '../confirm_modal/confirmModal';

export class Photo {
	public id: string | number;
	public dataUrl: string;
}

@Component({
	selector: 'photo-input',
	template: `
		<div class="flex-container flex-container-column flex-gap">
			<div>
				<label
					><strong>{{ title() }}</strong></label
				>
				<input #fileInput [attr.readonly]="readonly()" multiple type="file" (change)="fileProgress($event)" />
			</div>
			<div
				[class.container-column]="device.isHandset ? true : false"
				class="flex-container flex-justify-center flex-align-content-center flex-wrap flex-gap">
				@for(photoInput of photos(); track photoInput; let i = $index) {
				<div class="photo-container">
					<img downloadOnClick [src]="photoInput.dataUrl" alt="photo" height="150" width="150" />

					@if(!readonly()) {
					<button class="delete-button" (click)="removePhoto(photoInput, i)" mat-icon-button>
						<mat-icon>delete</mat-icon>
					</button>
					}
				</div>

				}
			</div>
		</div>
	`,
	styleUrls: ['./photo_input.scss'],
})
export class PhotoInput implements OnInit {
	//	The ids of the photos we want to display
	public photos = model.required<Photo[]>();

	//	Events for file creation and deletion
	public photoCreated = output<number | string>();
	public photoDeleted = output<number | string>();

	public title = input<string>('');
	public readonly = model<boolean>(false);

	@ViewChild('fileInput') fileInput: ElementRef;

	constructor(public api: ApiService, public device: DeviceService, public dialog: MatDialog) {}

	public ngOnInit() {
		//	Retrieve the initial files set for the photoids input into the component
		this.renderPhotos();
	}

	/**
	 * Render the photos based on the photoIds input.
	 * @returns A promise that resolves when all photos have been rendered.
	 */
	public renderPhotos() {
		this.photos().map((photo) => {
			this.api
				.getFile(photo.id as number)
				.toPromise()
				.then((file) => {
					let reader = new FileReader();
					reader.addEventListener(
						'load',
						() => {
							photo.dataUrl = reader.result as string;
						},
						false
					);

					if (file) {
						reader.readAsDataURL(file);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}

	public async removePhoto(photoInput, index) {
		const userConfirmationModal = this.dialog.open(ConfirmModal);
		userConfirmationModal.componentInstance.dialogue = 'Are you sure you would like to delete this photo?';

		const userInput = await userConfirmationModal
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result) {
					return true;
				} else {
					return false;
				}
			});

		if (userInput) {
			//	delete the photo in the backend
			if (photoInput.id) {
				await this.api.deleteFile(photoInput.id).toPromise();
				this.photoDeleted.emit(photoInput.id);
			}

			this.photos().splice(index, 1);
			this.photos.update((value) => this.photos());
		}
	}

	public fileProgress(fileInput: any) {
		let files = (fileInput.target as HTMLInputElement).files;

		let filesUploaded = new Promise((resolve, reject) => {
			for (let i = 0; i < files.length; ++i) {
				let file = files.item(i);
				let mimeType = file.type;
				if (mimeType.match(/image\/*/) == null) {
					return;
				}

				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (_event) => {
					let formData: FormData = new FormData();
					formData.append('files', file);

					this.api
						.postFile(formData)
						.toPromise()
						.then((newPhotoId) => {
							this.photos().push({ id: newPhotoId, dataUrl: reader.result as string });
							this.photoCreated.emit(newPhotoId);

							if ((i = files.length - 1)) {
								resolve(null);
							}
						});
				};
			}
		});

		filesUploaded.then(() => {
			(this.fileInput.nativeElement as HTMLInputElement).value = '';
		});
	}
}
