@if(settingsService.uiSettings().toolbarVertical) {

<div class="container">
	<div class="toolbar">
		<mat-toolbar style="height: 100vh; width: 58px; position: fixed; z-index: 2" color="primary">
			<button style="position: absolute; top: 5px; left: 5px" mat-icon-button [matMenuTriggerFor]="menu">
				<mat-icon>menu</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button style="outline: none" mat-menu-item [routerLink]="['../home']">Home</button>
				<button mat-menu-item [routerLink]="['new_purchase_order']">New Purchase Order</button>
				<button mat-menu-item [routerLink]="['receiving']">Receiving</button>
				@if(userService.isAdmin() || userService.isManager()) {
				<button mat-menu-item [routerLink]="['quotes']">Quotes</button>
				}
				<button mat-menu-item [routerLink]="['repair_orders']">Repair Orders</button>
				<button mat-menu-item [routerLink]="['preventative_maintenance/selector']">Preventative Maintenance</button>
				<button mat-menu-item [routerLink]="['customers']">Customers</button>
				<button mat-menu-item [routerLink]="['accounts']">Accounts</button>
				<button mat-menu-item [routerLink]="['vehicles']">Vehicles</button>
				<button mat-menu-item [routerLink]="['stores']">Stores</button>
				<button mat-menu-item [routerLink]="['payroll']">Payroll</button>
				<button mat-menu-item [routerLink]="['reporting']">Reporting</button>
				<button mat-menu-item [routerLink]="['settings']">Settings</button>
				<mat-divider></mat-divider>
				<user-clock-in></user-clock-in>
				<button mat-menu-item (click)="logoutClicked()">Logout</button>
			</mat-menu>

			<span class="envTitle" *ngIf="environmentTitle && environmentTitle != ''">{{ environmentTitle }}</span>

			<!-- <google-profile-button *ngIf="google && google.isLoggedIn()" style="bottom: 45px; left: 8px; position: absolute;"></google-profile-button> -->
			<span style="position: absolute; bottom: 45px; left: 8px">
				<theme-switcher></theme-switcher>
			</span>

			<span matTooltip="Online / Offline Indicator" style="left: 19px; bottom: 10px; position: absolute">
				<mat-icon *ngIf="isOnline" style="color: rgb(29, 202, 29)">wifi</mat-icon>
				<mat-icon *ngIf="!isOnline" style="color: red">wifi_off</mat-icon>
			</span>
			<span>
				<p class="accentColor" matTooltip="Version" style="font-size: 10px; position: fixed; right: 5px; bottom: -5px">v{{ version }}</p>
			</span>
		</mat-toolbar>
	</div>
	<div class="content">
		<div
			class="flex-container flex-container-column flex-align-items-center flex-justify-center flex-gap mt-3 pl-3 pr-3"
			*ngIf="router.url === '/home' || router.url === '/home/'">
			<div>
				<h2 class="Anton"><strong>Welcome, {{ user.username || 'user' }}</strong></h2>
			</div>

			<div>
				<strong>
					Welcome to the Imperial Truck Services website.
					<br />
					<br />
					Here you may log your purchase orders, repair orders and time on the job.
					<br />
					Coming soon your account will be able to be linked to a google account and your emails will be accessible from within this website.
					<br />
					<br />
					If you have any questions or concerns, please contact Dustin Dubiaga at 330-523-0710 or email him at imperialtruckservices&#64;gmail.com
					<br />
					<br />
					Thank you!
				</strong>
			</div>
		</div>
		<router-outlet></router-outlet>
	</div>
</div>

} @else {

<div class="verticalContainer">
	<div class="horizontalToolbar">
		<nav style="width: 100vw" class="flex-container flex-container-row flex-gap">
			<mat-toolbar class="toolbar-height" color="primary">
				<button mat-icon-button [matMenuTriggerFor]="menu">
					<mat-icon>menu</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button style="outline: none" mat-menu-item [routerLink]="['../home']">Home</button>
					<button mat-menu-item [routerLink]="['new_purchase_order']">New Purchase Order</button>
					<button mat-menu-item [routerLink]="['receiving']">Receiving</button>
					<button mat-menu-item [routerLink]="['quotes']">Quotes</button>
					<button mat-menu-item [routerLink]="['repair_orders']">Repair Orders</button>
					<button mat-menu-item [routerLink]="['preventative_maintenance/selector']">Preventative Maintenance</button>
					<button mat-menu-item [routerLink]="['customers']">Customers</button>
					<button mat-menu-item [routerLink]="['accounts']">Accounts</button>
					<button mat-menu-item [routerLink]="['vehicles']">Vehicles</button>
					<button mat-menu-item [routerLink]="['stores']">Stores</button>
					<button mat-menu-item [routerLink]="['payroll']">Payroll</button>
					<button mat-menu-item [routerLink]="['reporting']">Reporting</button>
					<button mat-menu-item [routerLink]="['settings']">Settings</button>
					<mat-divider></mat-divider>
					<user-clock-in></user-clock-in>
					<button mat-menu-item (click)="logoutClicked()">Logout</button>
				</mat-menu>
				<span class="spacer"></span>

				@if (environmentTitle && environmentTitle != '') {
				<span>{{ environmentTitle }}</span>
				}

				<!-- @if (google && google.isLoggedIn()) {
					<google-profile-button *ngIf="google && google.isLoggedIn()"></google-profile-button>
					} -->

				<theme-switcher></theme-switcher>
				<span matTooltip="Online / Offline Indicator">
					@if (isOnline) {
					<mat-icon class="accentColor">wifi</mat-icon>
					} @else {
					<mat-icon class="warnColor">wifi_off</mat-icon>
					}
				</span>
				<span>
					<p class="accentColor" matTooltip="Version" style="font-size: 10px; position: fixed; right: 5px; bottom: -5px">v{{ version }}</p>
				</span>
			</mat-toolbar>
		</nav>
	</div>
	<div
		[class]="{ containerWithVerticalToolbar: settingsService.uiSettings().toolbarVertical ? true : false, containerWithHorizontalToolbar: settingsService.uiSettings().toolbarVertical ? false : true}"
		class="content">
		<div>
			@if (router.url === '/home' || router.url === '/home/') {
			<div class="flex-container flex-container-column flex-align-items-center flex-justify-center pl-3 pr-3">
				<div>
					<h2><strong>Welcome, {{ user.username || 'user' }}</strong></h2>
				</div>

				<div>
					<strong>
						Welcome to the Imperial Truck Services website.
						<br />
						<br />
						Here you may log your purchase orders, repair orders and time on the job.
						<br />
						Coming soon your account will be able to be linked to a google account and your emails will be accessible from within this website.
						<br />
						<br />
						If you have any questions or concerns, please contact Dustin Dubiaga at 330-523-0710 or email him at imperialtruckservices&#64;gmail.com
						<br />
						<br />
						Thank you!
					</strong>
				</div>
			</div>
			}
			<router-outlet></router-outlet>
		</div>
	</div>
</div>

}
