import { Component, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { PreventativeMaintenanceRecord, PreventativeMaintenanceConfiguration, PreventativeMaintenanceField } from 'src/app/classes/preventative_maintenance';
import { ConfirmModal } from '../../confirm_modal/confirmModal';
import { MatDialog } from '@angular/material/dialog';
import { Location, DatePipe } from '@angular/common';
import { SnackBarService } from '../../snack_bar_alert/snackBarAlert';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserService } from 'src/app/services/user.service';
import { PhotoInput } from '../../file_input/photo_input.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Customer } from 'src/app/classes/customer';
import { Vehicle } from 'src/app/classes/vehicle';
import { User } from 'src/app/classes/user';
import { PdfMakeService } from 'src/app/services/pdfMake.service';

@Component({
	selector: 'preventative-maintenance-record',
	styleUrls: ['./record.component.scss'],
	templateUrl: './record.component.html',
})
export class PreventativeMaintenanceRecordComponent {
	public stepperFormGroups: UntypedFormGroup[] = [];
	public record: PreventativeMaintenanceRecord = null;
	public isLinear: boolean = true;
	public isReadOnly: boolean = false;

	private configuration: PreventativeMaintenanceConfiguration = null;

	@ViewChildren(PhotoInput) photoInputs: QueryList<PhotoInput>;

	constructor(
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public api: ApiService,
		public dialog: MatDialog,
		public location: Location,
		public userService: UserService,
		public formBuilder: UntypedFormBuilder,
		public pdfMakeService: PdfMakeService,
		public datePipe: DatePipe
	) {
		let recordId = this.activatedRoute.snapshot.params['recordId'];
		let configurationId = this.activatedRoute.snapshot.params['configurationId'];
		let vehicleId = this.activatedRoute.snapshot.queryParams['vehicleId'];

		if (this.activatedRoute.snapshot.queryParams.hasOwnProperty('isReadOnly')) {
			this.isReadOnly = this.activatedRoute.snapshot.queryParams['isReadOnly'];
		}

		let recordInitialized = Promise.resolve();

		if (recordId) {
			recordInitialized = this.api
				.getPreventativeMaintenanceRecord(recordId)
				.toPromise()
				.then((record: PreventativeMaintenanceRecord) => {
					this.record = record;
				});
		} else if (configurationId && vehicleId) {
			recordInitialized = this.api
				.getPreventativeMaintenanceConfiguration(configurationId)
				.toPromise()
				.then((configuration: PreventativeMaintenanceConfiguration) => {
					this.record = new PreventativeMaintenanceRecord();
					this.record.fields = configuration.fields;
					this.record.completed = new Date() as any;
					this.record.user_id = this.userService.user.id;
					this.record.configuration_id = configuration.id;
					this.record.vehicle_id = Number(vehicleId);
				});
		}

		recordInitialized
			.then(() => {
				return this.api
					.getPreventativeMaintenanceConfiguration(configurationId ? configurationId : this.record.configuration_id)
					.toPromise()
					.then((configuration) => {
						this.configuration = configuration;
					});
			})
			.finally(() => {
				this.setupStepperFormGroups();
			});
	}

	public setupStepperFormGroups() {
		this.stepperFormGroups = this.record.fields.map((field) => {
			return this.formBuilder.group({
				isNewLineItem: new UntypedFormControl(field.isNewLineItem),
				photoIds: new UntypedFormControl(field.photoIds),
				value: new UntypedFormControl(field.value),
				title: new UntypedFormControl(field.title),
			});
		});
	}

	public needsRepairValueChanged(form: UntypedFormGroup) {
		if (form) {
			let valueControl = form.get('value');
			let needsRepairControl = form.get('isNewLineItem');

			if (valueControl) {
				if (needsRepairControl && needsRepairControl.value) {
					valueControl.setValidators([Validators.required]);
				} else {
					valueControl.clearValidators();
				}

				valueControl.updateValueAndValidity();
				form.updateValueAndValidity();
			}
		}
	}

	public deleteRecord = () => {
		let componentRef = this.dialog.open(ConfirmModal);
		componentRef.componentInstance.dialogue = 'Are you sure you would like to delete this record?';

		return componentRef
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result) {
					if (this.record.id) {
						return this.api
							.deletePreventativeMaintenanceRecord(this.record.id)
							.toPromise()
							.then(() => true)
							.catch((error) => {
								SnackBarService.openSnackBarAlert(error.error.message, 'red');
								console.error(error);
							});
					} else {
						return Promise.resolve();
					}
				}
			})
			.then((deleted) => {
				if (deleted) {
					SnackBarService.openSnackBarAlert('Record deleted');
				}
				this.location.back();
			})
			.catch((error) => {});
	};

	public saveRecord = () => {
		if (this.isReadOnly) return Promise.resolve();

		let photosSaved = [];
		if (this.photoInputs && this.photoInputs.length) {
			photosSaved = this.photoInputs.map((input) => {
				//return input.saveFiles();
			});
		}

		return Promise.all(photosSaved)
			.then(() => {
				this.record.fields = this.stepperFormGroups.map((form) => form.value);

				this.record.fields.map((field, index) => {
					//field.photoIds = this.photoInputs.toArray()[index].ids;
				});

				return this.api
					.savePreventativeMaintenanceRecord(this.record)
					.toPromise()
					.then((id) => {
						if (id) {
							this.record.id = id;
						}

						SnackBarService.openSnackBarAlert('Successfully saved record');
						this.isReadOnly = true;
					});
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};

	public createRepairOrder = () => {
		let componentRef = this.dialog.open(ConfirmModal);
		componentRef.componentInstance.dialogue = 'Are you sure you would like to convert this record into a repair order?';

		return componentRef
			.afterClosed()
			.toPromise()
			.then((result) => {
				if (result) {
					return this.saveRecord().then(() => this.api.convertPreventativeMaintenanceRecordToRepairOrder(this.record.id).toPromise());
				}
			})
			.then((repairOrderId) => {
				if (repairOrderId) {
					let componentRef = this.dialog.open(ConfirmModal);
					componentRef.componentInstance.dialogue = 'Repair order created. Would you like to view the repair order?';

					return componentRef
						.afterClosed()
						.toPromise()
						.then((result) => {
							if (result) {
								this.router.navigate(['home', 'repair_orders'], {
									queryParams: { id: repairOrderId },
								});
							}
						})
						.catch((error) => {});
				}
			})
			.catch((error) => {
				SnackBarService.openSnackBarAlert(error.error.message, 'red');
				console.error(error);
			});
	};

	public vehicleSelectedHandler = (event: MatAutocompleteSelectedEvent) => {
		if (event && event.option && event.option.value) {
			this.record.vehicle_id = event.option.value;
		}
	};

	public createAndDownloadPdf = () => {
		let pdfDefinitionCreated = new Promise((resolve, reject) => {
			Promise.all([
				this.api.getCustomer(this.configuration.customer_id).toPromise(),
				this.api.getVehicle(this.record.vehicle_id).toPromise(),
				this.api.getUser(this.record.user_id).toPromise(),
			])
				.then((results) => {
					let customer = results[0],
						vehicle = results[1],
						user = results[2];

					try {
						function getDocumentHeader(
							customer: Customer,
							vehicle: Vehicle,
							user: User,
							record: PreventativeMaintenanceRecord,
							datePipe: DatePipe
						) {
							return {
								style: 'font10',
								table: {
									widths: ['50%', '50%'],
									body: [
										[
											{
												border: [false, false, false, false],
												bold: true,
												text: `Imperial Truck Services, Inc.
										P.O. Box 239 
										Bath, OH 44210 
										ImperialTruckServices@gmail.com 
										(330) 573-4777
										`,
											},
											{ border: [false, false, false, false], text: '' },
										],
										[
											{
												text: `Customer: ${customer.name}
										
											${customer.address} ${customer.street} ${customer.city}, ${customer.state} ${customer.zipcode}
											${customer.phone || ''}

											Date: ${datePipe.transform(record.completed, 'shortDate')}
											`,
											},
											{
												text: `Unit #: ${vehicle.unit_number || ''}
											Milage: ${vehicle.milage || ''}
											License Plate #: ${vehicle.license_number || ''}
											VIN: ${vehicle.vin || ''}
											Inspector Employee Number: ${user.employee_number || ''}
											`,
											},
										],
									],
								},
							};
						}
						function getDocumentFieldInspections(fields: PreventativeMaintenanceField[]) {
							let body = fields.map((field) => {
								return [{ text: field.title, border: [true, true, true, true] }, { text: field.isNewLineItem ? 'Yes' : 'No' }];
							}) as any;

							body.unshift([
								{ text: 'Inspection Performed:', style: 'font12', bold: true, border: [true, true, true, true] },
								{ text: 'Needs Repair:', bold: true, style: 'font12', border: [true, true, true, true] },
							]);

							return {
								style: 'font10',
								table: {
									widths: ['82%', '18%'],
									body: body,
								},
								headerRows: 1,
							};
						}
						function getDocumentNeedsRepairPages(fields: PreventativeMaintenanceField[], photos: { id: number; photo: any }[]): any[] {
							return fields.map((field, index) => {
								if (field.isNewLineItem || (field.photoIds && field.photoIds.length)) {
									let fieldPhotos = photos.filter((photo) => field.photoIds.includes(photo.id));
									let lines: any[] = [
										{ text: `${index + 1}: ${field.title}`, bold: true, margin: 5 },
										{ text: field.value, margin: 5 },
									];

									if (fieldPhotos && fieldPhotos.length) {
										fieldPhotos.forEach((photo) => {
											lines.push({
												image: photo.photo,
												width: 250,
												margin: 5,
											});
										});
									}

									return lines;
								}

								return null;
							});
						}

						/* let lineItemPhotos: { id: number; photo: any }[] = this.photoInputs
							.map((photoInput) => photoInput.photoPreviews)
							.reduce((prevValue, photoPreviews: any[]) => {
								photoPreviews.forEach((photo) => prevValue.push(photo));
								return prevValue;
							}, []); */

						let pdfDefinition = {
							content: [
								getDocumentHeader(customer, vehicle, user, this.record, this.datePipe),
								' ',
								getDocumentFieldInspections(this.record.fields),
								' ',
								` `,
								` `,
								{
									text: `Inspector Signature X_____________________________________________________________________________________________`,
									pageBreak: 'after',
								},
								...getDocumentNeedsRepairPages(this.record.fields, []) //TODO: MIKE REMOVE HARD CODDED EMPTY ARRAY
									.filter((lines) => {
										return lines != null;
									})
									.reduce((prevValue, currentValue) => {
										if (currentValue) {
											currentValue.forEach((value) => prevValue.push(value));
										}

										return prevValue;
									}, []),
							],

							styles: {
								font10: {
									fontSize: 10,
								},
								font8: {
									fontSize: 8,
								},
								font12: {
									fontSize: 12,
								},
							},
						};

						resolve(pdfDefinition);
					} catch (error) {
						console.error(error);
						reject(error);
					}
				})
				.catch((error) => reject(error));
		});

		return pdfDefinitionCreated.then((pdfDefinition) => {
			if (pdfDefinition) {
				//		Open the pdf in the browser
				let filename = 'preventative_maintenance_record.pdf';

				this.pdfMakeService.pdfMake.createPdf(pdfDefinition).download(filename);
			}
		});
	};
}
