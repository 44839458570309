<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
	<mat-card>
		<mat-card-title>
			@if(!loading) {
			<button mat-icon-button (click)="backButtonClicked()">
				<mat-icon>arrow_back</mat-icon></button
			>}
		</mat-card-title>

		<mat-card-content>
			@if(orderForm && !loading) {
			<div class="flex-container flex-container-row flex-align-items-center flex-justify-center flex-gap mt-3">
				<mat-card class="p-3">
					<mat-card-title>
						@if(!order.id) {
						<h5>
							<span>New Order</span>
						</h5>
						} @else if(order.id) {
						<h5>Order - {{ order.id }}</h5>
						}
					</mat-card-title>

					<mat-card-content>
						<form [formGroup]="orderForm">
							<mat-tab-group>
								<mat-tab label="Basic Information">
									<div class="mt-3">
										<div
											[class.flex-container-column]="device.isPhone"
											[class.flex-container-row]="!device.isPhone"
											class="flex-container flex-gap flex-align-items-center flex-justify-left mb-3">
											@if(userService.isAdmin() && this.order.id) {
											<mat-slide-toggle
												(change)="statusChangedClose($event)"
												[(ngModel)]="isClosed"
												[ngModelOptions]="{standalone: true}">
												Closed
											</mat-slide-toggle>
											@if(this.order.id && !isClosed) {
											<mat-slide-toggle
												(change)="statusChangedComplete($event)"
												[(ngModel)]="isComplete"
												[ngModelOptions]="{standalone: true}">
												Done
											</mat-slide-toggle>
											}

											<mat-slide-toggle (change)="isBilledChanged($event)" [(ngModel)]="isBilled" [ngModelOptions]="{standalone: true}">
												PO
											</mat-slide-toggle>
											} @if(this.order.id && !isClosed && !userService.isAdmin()){
											<mat-slide-toggle
												(change)="statusChangedComplete($event)"
												[(ngModel)]="isComplete"
												[ngModelOptions]="{standalone: true}">
												Done
											</mat-slide-toggle>
											}
										</div>
									</div>

									<div class="flex-container flex-container-column flex-gap">
										<customer-picker
											[customerSelectedHandler]="customerSelectedHandler"
											[customerId]="orderForm.get('customer_id').value"
											[validators]="[{key: 'required', message: 'Customer is <strong>required</strong>'}]"
											[formGroupParent]="orderForm"
											[formGroupControlName]="'customer_id'">
										</customer-picker>

										<mat-form-field class="w-100">
											<mat-label>Purchase Order Number</mat-label>
											<input formControlName="purchaseordernumber" matInput type="text" />
										</mat-form-field>

										<div [formGroup]="orderForm.get('vehicle')">
											<vehicle-picker
												class="w-100"
												[formGroupParent]="orderForm.get('vehicle')"
												[label]="'Originating Vehicle'"
												[vehicleId]="orderForm.get('vehicle.id')?.value"
												[formGroupControlName]="'id'"
												[validators]="[{key: 'required', message: 'Vehicle is <strong>required</strong>'}]"
												[optionSelectedHandler]="vehicleSelectedHandler">
											</vehicle-picker>
										</div>
									</div>

									<div class="flex-container flex-container-column flex-gap" [formGroup]="orderForm.get('vehicle')">
										<mat-form-field>
											<mat-label>Vehicle Type</mat-label>
											<mat-select (selectionChange)="vehicleTypeChanged($event)" formControlName="equipment_type">
												<mat-option></mat-option>
												<mat-option [value]="'truck'">Truck</mat-option>
												<mat-option [value]="'trailer'">Trailer</mat-option>
												<mat-option [value]="'equipment'">Equipment</mat-option>
											</mat-select>
											<mat-error *ngIf="formGroupHasError(orderForm.get('vehicle'), 'equipment_type', 'required')"
												>Vehicle type is <strong>required</strong></mat-error
											>
										</mat-form-field>
									</div>
									<div
										[class.flex-container-column]="device.isPhone"
										[class.flex-container-row]="!device.isPhone"
										class="flex-container flex-gap flex-align-items-center flex-justify-center"
										class="mt-3"
										[formGroup]="orderForm.get('vehicle')">
										<mat-form-field>
											<mat-label>Unit Number</mat-label>
											<input formControlName="unit_number" matInput type="text" />
											<mat-error *ngIf="formGroupHasError(orderForm.get('vehicle'), 'unit_number', 'required')">
												Unit number is <strong>required</strong>
											</mat-error>
										</mat-form-field>

										<mat-form-field>
											<mat-label>VIN</mat-label>
											<input formControlName="vin" matInput type="text" />
											<mat-error *ngIf="formGroupHasError(orderForm.get('vehicle'), 'vin', 'required')">
												VIN is <strong>required</strong>
											</mat-error>
										</mat-form-field>
									</div>

									<div
										[class.flex-container-column]="device.isPhone"
										[class.flex-container-row]="!device.isPhone"
										class="flex-container flex-gap flex-align-items-center flex-justify-center mt-3"
										[formGroup]="orderForm.get('vehicle')">
										<mat-form-field>
											<mat-label>Milage</mat-label>
											<input formControlName="milage" matInput type="number" />
											<mat-error *ngIf="formGroupHasError(orderForm.get('vehicle'), 'milage', 'required')">
												Milage is <strong>required</strong>
											</mat-error>
										</mat-form-field>

										<mat-form-field>
											<mat-label>License Number</mat-label>
											<input formControlName="license_number" matInput type="text" />
											<mat-error *ngIf="formGroupHasError(orderForm.get('vehicle'), 'license_number', 'required')">
												License number is <strong>required</strong>
											</mat-error>
										</mat-form-field>
									</div>
								</mat-tab>
								<mat-tab label="Line Items">
									<div style="max-height: 65vh; overflow: auto">
										<div
											class="mt-3 flex-container flex-gap"
											[class.flex-container-column]="device.isPhone"
											[class.flex-container-row]="!device.isPhone">
											<span class="mr-3"
												><strong>Item: </strong>{{ selectedLineItemIndex + 1 }} of {{ orderForm.get('lineItems').length }}</span
											>

											<button (click)="previousLineItem()" class="mr-3" mat-stroked-button color="warn">Previous Item</button>
											<button (click)="nextLineItem()" class="mr-3" mat-stroked-button color="primary">Next Item</button>

											<button (click)="addLineItem()" class="mr-3" mat-stroked-button color="basic">Add New Item</button>
											<button (click)="removeLineItem()" class="mr-3" mat-stroked-button color="basic">Delete Current Item</button>
										</div>

										<div *ngIf="selectedLineItem" [formGroup]="selectedLineItem">
											<div
												class="mt-3 mb-3 flex-container flex-gap"
												[class.flex-container-column]="device.isPhone"
												[class.flex-container-row]="!device.isPhone">
												<mat-form-field class="w-50">
													<mat-label>Complaint</mat-label>
													<textarea rows="3" formControlName="complaint" matInput type="text"></textarea>
													<mat-error *ngIf="formGroupHasError(selectedLineItem, 'complaint', 'required')">
														Customer complaint is
														<strong>required</strong>
													</mat-error>
												</mat-form-field>

												<mat-form-field class="w-50">
													<mat-label>Cause</mat-label>
													<textarea rows="3" formControlName="cause" matInput type="text"></textarea>
													<mat-error *ngIf="formGroupHasError(selectedLineItem, 'cause', 'required')">
														Cause is <strong>required</strong>
													</mat-error>
												</mat-form-field>

												<mat-form-field class="w-50">
													<mat-label>Correction</mat-label>
													<textarea rows="3" formControlName="correction" matInput type="text"></textarea>
													<mat-error *ngIf="formGroupHasError(selectedLineItem, 'correction', 'required')">
														Correction is
														<strong>required</strong>
													</mat-error>
												</mat-form-field>
											</div>

											<div
												[class.flex-container-column]="device.isPhone"
												[class.flex-container-row]="!device.isPhone"
												class="mt-1 flex-container flex-gap">
												<div class="mr-3">
													<button (click)="addProduct()" mat-stroked-button color="basic">+ New Product</button>
												</div>

												<div class="mr-3">
													<button (click)="openVehicleProductsSelector()" mat-stroked-button color="basic">
														+ Inventory Product
													</button>
												</div>

												<mat-form-field class="w-75px mr-3">
													<mat-label>Hours</mat-label>
													<input formControlName="hours" matInput type="number" />
													<mat-error *ngIf="formGroupHasError(selectedLineItem, 'hours', 'required')">
														Hours are <strong>required</strong>
													</mat-error>
												</mat-form-field>
												<mat-form-field class="w-75px mr-3">
													<mat-label>Total Hours</mat-label>
													<input disabled formControlName="total_hours" matInput type="number" />
												</mat-form-field>
												<mat-form-field *ngIf="userService.isAdmin() || userService.isManager()" class="w-75px">
													<mat-label>Rate</mat-label>
													<input formControlName="rate" matInput type="number" />
													<mat-error *ngIf="formGroupHasError(selectedLineItem, 'rate', 'required')">
														Rate is <strong>required</strong>
													</mat-error>
												</mat-form-field>
											</div>

											<div style="max-width: calc(100vw - 158px); overflow: auto">
												<entity-selector-table
													[tableMaxHeight]="'35vh'"
													[dataSource]="tableDataSource"
													[selectorTableColumns]="selectorTableColumns"
													[pageSize]="50">
												</entity-selector-table>
											</div>
										</div>
									</div>
								</mat-tab>

								<!-- 
								<mat-tab label="Photos">
									<photo-input
										(photoCreated)="onPhotoCreated($event)"
										(photoDeleted)="onPhotoDeleted($event)"
										[photos]="photos"></photo-input>
								</mat-tab>
							-->

								@if(userService.isAdmin() || userService.isManager()) {
								<mat-tab label="Invoice">
									<div
										[class.flex-container-column]="device.isPhone"
										[class.flex-container-row]="!device.isPhone"
										class="mt-3 mb-3 flex-container flex-gap">
										@if(this.order.id) {
										<mat-slide-toggle (change)="statusChangedClose($event)" [(ngModel)]="isClosed" [ngModelOptions]="{standalone: true}">
											Closed
										</mat-slide-toggle>
										} @if(this.order.id && !isClosed) {
										<mat-slide-toggle
											(change)="statusChangedComplete($event)"
											[(ngModel)]="isComplete"
											[ngModelOptions]="{standalone: true}">
											Done
										</mat-slide-toggle>
										}

										<mat-slide-toggle (change)="isBilledChanged($event)" [(ngModel)]="isBilled" [ngModelOptions]="{standalone: true}">
											PO
										</mat-slide-toggle>
									</div>

									<div
										class="mt-3 flex-container flex-gap"
										[class.flex-container-column]="device.isPhone"
										[class.flex-container-row]="!device.isPhone">
										<mat-form-field>
											<mat-label>Tax Rate</mat-label>
											<input [(ngModel)]="taxRate" matInput type="number" [ngModelOptions]="{standalone: true}" />
										</mat-form-field>
										<div class="mb-3">
											<mat-slide-toggle [(ngModel)]="taxExempt" [ngModelOptions]="{standalone: true}">Tax Exempt </mat-slide-toggle>
										</div>
									</div>

									<div
										[class.flex-container-column]="device.isPhone"
										[class.flex-container-row]="!device.isPhone"
										class="flex-container flex-gap">
										<mat-form-field>
											<mat-label>Other Fee's</mat-label>
											<input [(ngModel)]="otherFees" matInput type="number" [ngModelOptions]="{standalone: true}" />
										</mat-form-field>
										<mat-form-field>
											<mat-label>Disposal Fee</mat-label>
											<input [(ngModel)]="disposalFees" matInput type="number" [ngModelOptions]="{standalone: true}" />
										</mat-form-field>
									</div>

									<div>
										<loading-button [text]="'Download Invoice'" [action]="downloadInvoiceClicked"></loading-button>
									</div>
								</mat-tab>
								}
							</mat-tab-group>
						</form>
						<br />

						<mat-divider></mat-divider>

						<br />

						<div class="flex-container flex-gap" [class.flex-container-column]="device.isPhone" [class.flex-container-row]="!device.isPhone">
							<loading-button [text]="'Submit Order'" [action]="onRepairOrderSubmit" [disabled]="userCantEdit() || loading"></loading-button>

							@if(this.order.id) {
							<loading-button [text]="'Delete Order'" [action]="deleteRepairOrderClicked" [disabled]="userCantEdit() || loading"></loading-button
							>}
						</div>
					</mat-card-content>
				</mat-card>
			</div>
			} @else if (loading) {
			<div class="flex-container flex-align-items-center flex-justify-center p-3">
				<loading-spinner></loading-spinner>
			</div>
			}
		</mat-card-content>
	</mat-card>
</div>
